import React from 'react'
import classes from './Feature.module.css'
import checkIcon from '../../../../images/icons/check.svg'

export default props => (
  <div className={classes.Feature}>
    <div>
      <img src={checkIcon} alt="check icon" />
    </div>
    <div>
      <span>{props.text}</span>
    </div>
  </div>
)
