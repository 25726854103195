import React from 'react'
import classes from './Statistics.module.css'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import LockIcon from '../../../../images/icons/lock_white.svg'
import Paper from '@material-ui/core/Paper'

export default () => {
  const query = useStaticQuery(
    graphql`
      query {
        image_1: file(relativePath: { eq: "examples/fieldsStatistics.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image_2: file(
          relativePath: { eq: "examples/questionsStatistics.png" }
        ) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <div className={classes.Container}>
      <div className={classes.SubContainer}>
        <div className={classes.Example}>
          <Paper elevation={5}>
            <Img
              fluid={query.image_1.childImageSharp.fluid}
              alt="Fields Statistics"
              className={classes.Img}
            />
          </Paper>
        </div>
        <div className={classes.Description}>
          <h2 className={classes.Title}>Fields Statistics</h2>
          <span>
            After completing <h3 className={classes.H}>Mock Exam</h3> using PMP
            Exam Simulator you are able to get detailed Statistics of each
            Field. Our Simulator shuffles{' '}
            <h4 className={classes.H}>sample questions</h4> all the time and you
            will get a random number of questions from each Field. In the
            Statistics you can monitor if a particular Field requires
            improvement. There is an option to lock each Field by pressing{' '}
            <img src={LockIcon} className={classes.Icon} alt="lock" /> and
            practice answering questions selectively.
          </span>
        </div>
      </div>
      <div className={classes.SubContainer}>
        <div className={classes.Example}>
          <Paper elevation={5}>
            <Img
              fluid={query.image_2.childImageSharp.fluid}
              alt="Questions Statistics"
              className={classes.Img}
            />
          </Paper>
        </div>
        <div className={classes.Description}>
          <h2 className={classes.Title}>Questions Statistics</h2>
          <span>
            After getting through PMP Exam Simulator you will also get step by
            step Statistics of every question you answered. You will be able to
            go back to each question and try to answer it one more time. Look at
            the mistakes you've made, read a comprehensive explanation, make
            sure you understand the logic behind the correct answer and keep
            moving forward.{' '}
            <h5 className={classes.H}>Effective PMP Preparation</h5> is all
            about continuous practice!
          </span>
        </div>
      </div>
    </div>
  )
}
