import React from 'react'
import classes from './Jumbotron.module.css'
import Button from '@material-ui/core/Button'
import { FaGraduationCap } from 'react-icons/fa/'
import { Link } from 'gatsby'
import getRandomQuestion from '../../../../utils/question/getRandom'
import { connect } from 'react-redux'
import * as actions from '../../../../store/actions/index'
import './library.scss'

const jumbotron = props => {
  const questionURL = getRandomQuestion().url

  const buttonClick = () => {
    if (props.lockedField) props.onFieldRelease()
  }

  return (
    <div className={classes.Jumbotron}>
      <div className={classes.JumbotronContent}>
        <center>
          <div className={classes.MainText}>
            <strong className={classes.Strong}>ErudiCAT</strong>
            <br />
            the most efficient way to make
            <br />
            <strong className={classes.Strong}>YOU</strong>
            <br />
            <div className={classes.Div}>
              <span className={classes.LeftSpan}>pass</span>
            </div>
            <span className={classes.PMP}> PMP </span>
            <div className={classes.Div}>
              <span className={classes.RightSpan}>exam!</span>
            </div>
            <br />
            &nbsp;&nbsp;
            <span className={`${classes.UpdatedText} ${classes.Strong}`}>
              updated 2 Jan, 2021
            </span>
          </div>
          <div className={classes.Button}>
            <Link to={`/${questionURL}`}>
              <Button
                variant="contained"
                style={{ backgroundColor: '#5fba7d', color: '1C132A' }}
                onClick={buttonClick}
              >
                <FaGraduationCap />
                &nbsp;&nbsp;<strong>START STUDYING!</strong>&nbsp;&nbsp;
                <FaGraduationCap />
              </Button>
            </Link>
          </div>
        </center>
      </div>
      <center>
        <div className="bookshelf_wrapper">
          <ul className="books_list">
            <li className="book_item first"></li>
            <li className="book_item second"></li>
            <li className="book_item third"></li>
            <li className="book_item fourth"></li>
            <li className="book_item fifth"></li>
            <li className="book_item sixth"></li>
          </ul>
        </div>
      </center>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    lockedField: state.field.locked
  }
}

const mapActionToProps = dispatch => {
  return {
    onFieldRelease: () => dispatch(actions.fieldRelease())
  }
}

export default connect(mapStateToProps, mapActionToProps)(jumbotron)
