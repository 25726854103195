import React from 'react'
import classes from './Landing.module.css'
import SEO from '../../seo'
import Jumbotron from './Jumbotron/Jumbotron'
import ExamSimulator from './ExamSimulator/ExamSimulator'
import Statistics from './Statistics/Statistics'
import PerformanceChart from './PerformanceChart/PerformanceChart'

export default () => {
  return (
    <div className={classes.PageContainer}>
      <SEO
        title="PMP Exam Simulator | Sample Questions | PMP Preparation"
        keywords={[
          `PMP Exam Simulator`,
          `PMP Preparation`,
          `PMP Sample Questions`
        ]}
        description="PMP Exam Simulator. 100% as Tough as Actual Exam. 1k+ Sample Questions. Performance Chart. Statistics."
      ></SEO>
      <Jumbotron />
      <ExamSimulator />
      <Statistics />
      <PerformanceChart />
    </div>
  )
}
