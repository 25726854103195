import React from 'react'
import classes from './PerformanceChart.module.css'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import Paper from '@material-ui/core/Paper'

export default () => {
  const query = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "examples/performanceChart.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <div className={classes.Container}>
      <div className={classes.Description}>
        <h2 className={classes.Title}>Performance Chart</h2>
        <span>
          PMP Exam Simulator processes your Mock Exam results and generates
          Performance Chart to show where you stand. The closer you are to the
          'Target' or even better 'Above Target' score, the greater the chances
          are to get <h6 className={classes.H}>PMP Certification</h6> from the
          first attempt. Do not rush to schedule your PMP Exam if our PMP Exam
          Simulator score is 'Below Target' or 'Needs Improvement'. It's not
          recommended to rely only on a single good result, anyone can get
          lucky. You are ready when you have consecutive high scores.
        </span>
      </div>
      <div className={classes.Example}>
        <Paper elevation={5}>
          <Img
            fluid={query.file.childImageSharp.fluid}
            alt="Performance Chart"
            className={classes.Img}
          />
        </Paper>
      </div>
    </div>
  )
}
