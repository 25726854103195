import React from 'react'
import Layout from '../hoc/Layout/Layout'
import Landing from '../components/Pages/Landing/Landing'

export default ({ data }) => {
  return (
    <Layout landing={true}>
      <Landing />
    </Layout>
  )
}
