import React from 'react'
import classes from './ExamSimulator.module.css'
import Examinator from '../../../Examinator/Examinator'
import getRandomQuestion from '../../../../utils/question/getRandom'
import Button from '@material-ui/core/Button'
import { Link } from 'gatsby'
import { connect } from 'react-redux'
import * as actions from '../../../../store/actions/index'
import Feature from '../../Profile/Feature/Feature'

const examSimulator = props => {
  const questionURL = getRandomQuestion().url

  const buttonClick = () => {
    if (props.lockedField) props.onFieldRelease()
  }

  return (
    <div className={classes.Container}>
      <div className={classes.Description}>
        <h2 className={classes.Title}>PMP Exam Simulator</h2>
        <Feature text="Running at the same pace and 100% as tough as the actual PMP Exam" />
        <Feature text="Adjustable duration: from 30 minutes up to 230 minutes" />
        <Feature text="Acceleration feature makes your Mock Exam even harder" />
        <Feature text="Display or hide correct answers depending on your goal" />
        <Feature text="Each Mock Exam is unique - the questions are randomly selected from the database" />
        <Feature text="Answers are regularly shuffled to make sure even those with the best visual memory have to rely only on their knowledge" />
        <div className={classes.Button}>
          <Link
            to={
              props.isAuthenticated
                ? `/${questionURL}`
                : `/pmp-training-sign-in`
            }
          >
            <Button
              variant="contained"
              style={{ backgroundColor: '#5fba7d', color: '1C132A' }}
              onClick={buttonClick}
            >
              <strong>try it now!</strong>
            </Button>
          </Link>
        </div>
      </div>
      <div className={classes.Example}>
        <Examinator redirect={questionURL} />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    lockedField: state.field.locked
  }
}

const mapActionToProps = dispatch => {
  return {
    onFieldRelease: () => dispatch(actions.fieldRelease())
  }
}

export default connect(mapStateToProps, mapActionToProps)(examSimulator)
